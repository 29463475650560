
import { defineComponent ,reactive } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";
import LittleMessage from "./modules/littleMessage.vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/utils/axios";

// import Tag from "./modules/Tag.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
    LittleMessage,
  },
  data() {
    return {
      tabPosition: "left",
      currentPage1: 1,
      currentPage2: 1,
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
      demoImg: require("@/assets/1new/关于我们/关于我们.jpg"),
      videoS: require("@/assets/关于我们/djx.mp4"),
    };
  },
  methods: {
      go1(a:any){
      // console.log(a);
      this.$router.push('/college/'+a)
         setTimeout(() => {
      location.reload();
      }, 1000);
    },
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
    },
    handleSizeChange(val: any) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val: any) {
      console.log(`当前页: ${val}`);
    },
    handleSizeChange2(val: any) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange2(val: any) {
      console.log(`当前页: ${val}`);
    },
  },
   setup() {
    // const router = useRouter();
    const route = useRoute();
    const video = reactive({ data: "" });
    const videoList = reactive({ data: "" });
     var mess = reactive({ data: "" });
    axios
      .get(
        "https://www.xsy985.com/api/news/news?page=1&page_size=5&news_type=3"
      )
      .then(function (response) {
        console.log(response.data, "资讯");
        mess.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(router,'1111111',route);
    // console.log(route,"route");
    console.log(route.params, "route");
    axios
      .get(
        "https://www.xsy985.com/api/course/course/" + route.params.id
      )
      .then(function (response) {
        console.log(response.data, "video");
         response.data.video = "https://www.xsy985.com/api/course/course/" + route.params.id+"/play_Video/"
        video.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
        // 2
       axios
        .get(
        "https://www.xsy985.com/api/course/course/"+route.params.id+"/random_course/",
          // "https://www.xsy985.com/api/course/course/?page=1&page_size=2&course_type=3"
        )
        .then(function (response) {
          // console.log(response.data,"名师讲堂");
          videoList.data = response.data;
          // console.log(video1.data, "1");
        })
        .catch(function (error) {
          console.log(error);
        });
        // end
    return {
      video,
      videoList,
      mess
    };
  },
});
